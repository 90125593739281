
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';
import { Field } from 'vee-validate';
import { VueCookieNext } from 'vue-cookie-next';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';

export default defineComponent({
  name: 'kt-drawer-course-copyDrawer',
  components: { Field },
  props: {
    data: { type: Object },
  },
  data() {
    return {
      closecourse:true,
      unit_cost_total: '',
      percentageInfo: '',
      courseTermCount: 0,
      tranche: [],
      association: [],
      stipenchecked: 'no',
      managementchecked: 'no',
      employmentchecked: 'no',
      reassesmentchecked: 'no',
      totchecked: 'no',
      assesmentchecked: 'no',
      certificationchecked: 'no',

      instituteTypes: [],
      contactList: [],
      courseList: [],
      categoryList: [],
      sectorList: [],
      courseAlias: [],
      skillLevelList: [],
      test: [],
      load: false,
      loading: false,
      update_status: 0,
      formData: {
        id: '' as any,
        entity_id: '',
        tranche: '',
        entity_tranche_contract_id: '',
        course_type: '',
        course_category: '',
        course_sector: '',
        unit_cost_total: '',
        x_course_name_id: '',
        code: '',
        course_name: '',

        total_target_batches: '',
        trainee_per_batch: '',
        min_day_per_month: '',
        total_course_cost: 0,
        total_target_trainee: '',
        min_age: '',
        max_age: '',
        age_grace_in_month: '',
        course_objective: '',
        entry_qualification: '',
        month: '',
        hour: '',
        day: '',
        session: '',
        term: '',

        isStipendCourse: 0,
        isManagementCourse: 0,
        isEmploymentEligible: 0,
        isReassessmentEnabled: 0,
        isTotCourse: 0,
        certification_authority: '',
        skill_level_id: '' as any,

        // percentageInfo: "",
      } as any,

      configuration: {
        e_grace: '' as any,
        dropout_below_perc: '' as any,
        dropout_rep_perc_per_batch: '',
        assessment_applicable: 0,
        certification_applicable: 0,
        emp_bill_claim_perc: '',
      } as any,

      // bill_data: [] as any,
      terms: [] as any,
      temp_data: [] as any,
      bills: [] as any,
      tempbills: [] as any,
      // bill_data_final: [] as any,
    };
  },
  async created() {
    await this.getAssociation();
    await this.getTranche();
    await this.getContactList();
    await this.getCourseTypeList();
    await this.getSkillLevelList();
    await this.getCategoryTypeList();
    await this.getSectorList();
    await this.getCourseAliasList();
    this.emitter.on('course-copy-drawer', async (data) => {
      this.loading = false;
      this.load = true;
      (this.stipenchecked = 'no'),
        (this.managementchecked = 'no'),
        (this.employmentchecked = 'no'),
        (this.reassesmentchecked = 'no'),
        (this.totchecked = 'no');
      (this.assesmentchecked = 'no'), (this.certificationchecked = 'no');
      if (data?.isStipendCourse == 1) {
        this.stipenchecked = 'yes';
      }
      if (data?.isManagementCourse == 1) {
        this.managementchecked = 'yes';
      }
      if (data?.isEmploymentEligible == 1) {
        this.employmentchecked = 'yes';
      }
      if (data?.isReassessmentEnabled == 1) {
        this.reassesmentchecked = 'yes';
      }
      if (data?.isTotCourse == 1) {
        this.totchecked = 'yes';
      }
      if (data?.assessment_applicable == 1) {
        this.assesmentchecked = 'yes';
      }
      if (data?.certification_applicable == 1) {
        this.certificationchecked = 'yes';
      }
      this.configuration.e_grace = data?.course_config_info?.e_grace;
      this.configuration.dropout_below_perc =
        data?.course_config_info?.dropout_below_perc;
      this.configuration.dropout_rep_perc_per_batch =
        data?.course_config_info?.dropout_rep_perc_per_batch;
      this.configuration.emp_bill_claim_perc =
        data?.course_config_info?.emp_bill_claim_perc;
      this.terms = [] as any;

      this.terms = data?.course_term_info.map((term) => {
        return {
          id: term.id,
          course_info_id: term.course_info_id,
          month: term.term_month,
          day: term.term_day,
          session: term.term_session,
          hour: term.term_hour,
          week: term.week_session,
          created_by: term.created_by,
          updated_by: term.updated_by,
          created_at: term.created_at,
          updated_at: term.updated_at,
        };
      });

      // bills load
      this.bills = [] as any;
      this.bills = data.course_bill_info;
      this.unit_cost_total = data.unit_cost_total;

      console.log(this.bills);
      //console.log(3 - this.bills.length);
      if (3 - this.bills.length) {
        for (let i = 0; i < 3 - this.bills.length; i++) {
          let temp = {
            id: '',
            x_claim_milestone_id: `CLAIM-${this.bills.length + (i + 1)}`,
            entity_course_id: '',
            x_training_milestone_id: '',
            // milestone_param: "",
            claim_percentage: '',
            adj_adv_reduction_perc: '',
          };
          this.tempbills.push(temp);
          // console.log(i);
        }
        // console.log(this.tempbills);
        if (this.bills.length > 0) {
          this.bills.push(this.tempbills);
        } else {
          this.bills = this.tempbills;
        }

        this.tempbills = [] as any;
        // console.log("after push" + this.bills);
      }

      this.formData = data;
      this.load = false;
    });
  },
  methods: {
    isStipendCourseCheck(event) {
      if (event.target.checked) {
        this.formData.isStipendCourse = 1;
        console.log(this.formData.isStipendCourse);
      } else {
        this.formData.isStipendCourse = 0;
        console.log(this.formData.isStipendCourse);
      }
    },
    isManagementCourseCheck(event) {
      if (event.target.checked) {
        this.formData.isManagementCourse = 1;
      } else {
        this.formData.isManagementCourse = 0;
      }
    },
    isEmploymentCourseCheck(event) {
      if (event.target.checked) {
        this.formData.isEmploymentEligible = 1;
      } else {
        this.formData.isEmploymentEligible = 0;
      }
    },
    isReassessmentCheck(event) {
      if (event.target.checked) {
        this.formData.isReassessmentEnabled = 1;
      } else {
        this.formData.isReassessmentEnabled = 0;
      }
    },
    isToTCheck(event) {
      if (event.target.checked) {
        this.formData.isTotCourse = 1;
      } else {
        this.formData.isTotCourse = 0;
      }
    },
    isAssesmentCheck(event) {
      if (event.target.checked) {
        this.configuration.assessment_applicable = 1;
      } else {
        this.configuration.assessment_applicable = 0;
        console.log(
          'assessment_applicable' + this.configuration.assessment_applicable
        );
      }
    },
    isCertificationCheck(event) {
      if (event.target.checked) {
        this.configuration.certification_applicable = 1;
        console.log(
          'certification_applicable' +
            this.configuration.certification_applicable
        );
      } else {
        this.configuration.certification_applicable = 0;
        console.log(
          'certification_applicable' +
            this.configuration.certification_applicable
        );
      }
    },
    totalcourseCost() {
      if (this.formData.total_target_trainee && this.formData.unit_cost_total) {
        let targetTrainee = +this.formData.total_target_trainee;
        let unitcost = +this.formData.unit_cost_total;
        this.formData.total_course_cost = targetTrainee * unitcost;
      }
    },
    async getContractPercentage(id) {
      await ApiService.get('entity/contract/show/' + id)
        .then((response) => {
          this.percentageInfo = response.data.data?.contribution_percentage;
          this.formData.min_age = response.data?.data?.min_age;
          this.formData.max_age = response.data?.data?.max_age;
          this.formData.age_grace_in_month =
            response.data?.data?.age_grace_in_month;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseTermGenerate() {
      console.log('terms' + this.terms.length);
      console.log('term_no' + this.formData?.term);

      // get last index
      let lastcourseTermIndex = this.terms.length;
      if (lastcourseTermIndex < +this.formData?.term) {
        for (let i = lastcourseTermIndex; i < +this.formData?.term; i++) {
          let term_data_temp = {
            id: '',
            course_info_id: '',
            term_no: lastcourseTermIndex + 1,
            month: '',
            day: '',
            session: '',
            hour: '',
            class_type: '',
            week: '',
          };

          this.terms.push(term_data_temp);
          this.temp_data.push(term_data_temp);
        }
      } else if (+this.formData?.term == +lastcourseTermIndex) {
        this.terms = [] as any;
        this.terms = this.temp_data;
        // console.log("2nd");
        // console.log("temp data length" + this.temp_data.length);
      } else {
        let lastIndex = this.terms.length;
        let spliceNumber = +lastIndex - +this.formData?.term;
        this.terms = this.terms.slice(0, -spliceNumber);
        // console.log("3rd");
        // console.log("temp data length" + this.temp_data.length);
      }
    },
    async getSkillLevelList() {
      await ApiService.get('configurations/course_skill_level/list')
        .then((response) => {
          this.skillLevelList = response.data.data;
          console.log('skill level :');
          console.log(response.data.data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      await ApiService.get('entity/list')
        .then((response) => {
          this.association = response.data.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async gettrainning_instituteTypes() {
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.instituteTypes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getContactList() {
      await ApiService.get(
        'entity/contract/list?tranche=' +
          this.formData.tranche +
          '&entity_info_id=' +
          this.formData.entity_id
      )
        .then((response) => {
          console.log(this.formData.entity_id);
          this.contactList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseTypeList() {
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseAliasList() {
      await ApiService.get('configurations/alias_name/list')
        .then((response) => {
          this.courseAlias = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCategoryTypeList() {
      await ApiService.get('configurations/category_type/list')
        .then((response) => {
          this.categoryList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSectorList() {
      await ApiService.get('configurations/sector_type/list')
        .then((response) => {
          this.sectorList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async formSubmit() {
       this.closecourse = true;
      if (this.closecourse == true) {
            Swal.fire({
              title: 'Submission Error',
              html: 'Submission Error Try Again Later',
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
      }
      else {
        let formData = new FormData();
      // course info set
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }

      for (var key in this.configuration) {
        formData.set(key, this.configuration[key]);
      }
      let user_id = VueCookieNext.getCookie('_seip_user');
      let role_id = VueCookieNext.getCookie('_seip_role_id');
      formData.set('user_id', user_id.id);
      formData.set('role_id', role_id);
      // formData.set("course_id", this.formData.id);

      formData.set('all_term', JSON.stringify(this.terms));
      formData.set('bill_info', JSON.stringify(this.bills));

      console.log(JSON.stringify(this.configuration));

      this.loading = true;
      await ApiService.post('course/save', formData)
        .then((response) => {
          if (response.status == 200) {
            DrawerComponent?.hideAll();

            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.emitter.emit('course-approve-update', true);
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Submission Error',
              html: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });   
      } 
    },
  },
  setup() {
    const CourseInfoSchema = Yup.object().shape({});
    return {
      CourseInfoSchema,
    };
  },
});
